<template>
  <div class="">
    <section v-if="info" id="info" class="bg-kidsAcademy inset-0 absolute z-50 overflow-y-scroll">
      <div class="max-w-8xl mx-auto py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0">
        <button @click="toggle" class="w-6 h-6 rounded-full left-0 focus:outline-none">
          <svg :class="`${$i18n.locale}` == 'ar' ? '' : 'transform rotate-180'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 490.667 490.667" style="enable-background:new 0 0 490.667 490.667;" xml:space="preserve">
          <path style="fill:white;" d="M245.333,0C109.839,0,0,109.839,0,245.333s109.839,245.333,245.333,245.333
            s245.333-109.839,245.333-245.333C490.514,109.903,380.764,0.153,245.333,0z"/>
          <path style="fill:#5aa5de;" d="M290.133,104.533c-19.139-18.289-49.277-18.289-68.416,0c-18.893,18.881-18.902,49.503-0.021,68.395
            c0.007,0.007,0.014,0.014,0.021,0.021l19.2,19.2H117.333C87.878,192.149,64,216.027,64,245.483
            c0,29.455,23.878,53.333,53.333,53.333h123.584l-19.2,19.2c-17.522,20.175-15.371,50.734,4.804,68.257
            c18.259,15.858,45.423,15.799,63.612-0.139l118.251-118.251c12.492-12.496,12.492-32.752,0-45.248L290.133,104.533z"/>
          </svg>
        </button>
        <div class="mt-6 md:mt-10 lg:mt-16 lg:flex">
          <div class="lg:w-3/12 text-white text-lg">
            <div class="font-bold text-2xl md:mb-2">+ {{ $t("AbnaYousef.project_info.project_info") }}</div>
            <br>
            {{ $t("AbnaYousef.project_info.work.1") }} /
            <br class="hidden lg:block">
            {{ $t("AbnaYousef.project_info.work.2") }} /
            <br class="hidden lg:block">
            {{ $t("AbnaYousef.project_info.work.3") }} /
            <br class="hidden lg:block">
            {{ $t("AbnaYousef.project_info.work.4") }} /
          </div>
          <div class="lg:w-9/12 text-white mt-6">
            <p class="text-4xl font-bold">{{ $t("AbnaYousef.project_info.description.title") }}</p>

            <div class="">
              <p class="mt-6 text-justify leading-7 text-lg">
                {{ $t("AbnaYousef.project_info.description.content_1") }}
              </p>

              <p class="mt-6 text-justify leading-7 text-lg">
                 {{ $t("AbnaYousef.project_info.description.content_2") }}
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="hero" class="w-full h-64 md:h-auto lg:h-screen relative bg-center">
      <div class="flex items-center h-full absolute right-0 z-10">
          <button @click="toggle" class="bg-kidsAcademy w-6 md:w-10 py-1 text-white font-extrabold text-lg md:text-3xl rounded-l-xl flex justify-center items-center focus:outline-none">
              +
          </button>
      </div>
      <div class="flex justify-center items-center h-full bg-white relative">
        <img src="../assets/images/kidsAcademy/LOGO.svg" alt="AbnaYousef" class="w-full h-full max-w-lg">
      </div>
    </section>

    <section class="background_kidsAcademy bg-kidsAcademy bg-right bg-no-repeat bg-clip-text lg:bg-clip-content  text-white">
      <div class="max-w-6xl mx-auto px-4 xl:px-0 z-20 bg-kidsAcademy md:bg-transparent" :class="`${$i18n.locale}` == 'ar' ? 'flex justify-end' : ''">
        <div class="lg:w-9/12 py-8 md:py-20 max-w-xl">
          <p class="text-lg md:text-5xl lg:text-7xl font-bold">{{ $t("kidsAcademy.1.title") }}</p>
          <p class="text-sm md:text-base font-bold mt-4">{{ $t("kidsAcademy.1.description") }}</p>
        </div>
      </div>
    </section>

    <section class="bg-kidsAcademy ">
      <img src="../assets/images/kidsAcademy/CREATION.svg" alt="">

      <img src="../assets/images/kidsAcademy/EDUCATION.svg" alt="" class="mt-16">
    </section>

    <section class="bg-white max-w-6xl mx-auto px-4 xl:px-0 md:flex items-center space-x-10 py-20">
      <div class="md:w-1/3">
        <img src="../assets/images/kidsAcademy/03-SMILE.png" alt="">
      </div>
      <div class="md:w-2/3">
        <p class="text-lg md:text-5xl lg:text-6xl font-bold text-kidsAcademy">{{ $t("kidsAcademy.2.title") }}</p>
        <p class="text-sm md:text-base font-bold mt-4 text-kidsAcademy">{{ $t("kidsAcademy.2.description") }}</p>
      </div>
    </section>

    <section class="md:flex items-center">
      <div class="">
        <img src="../assets/images/kidsAcademy/05-IMAGINATION.jpg" alt="">
      </div>
      <div class="">
        <img src="../assets/images/kidsAcademy/04-K ACADEMY.jpg" alt="">
      </div>
    </section>

    <section id="color">
      <div class="max-w-5xl mx-auto px-4 xl:px-0 mt-24">
        <img src="../assets/images/kidsAcademy/color.jpg" alt="">
      </div>
    </section>


    <section class="bg-white max-w-6xl mx-auto px-4 xl:px-0 md:flex items-center space-x-10 py-20">
      
      <div class="md:w-2/3">
        <p class="text-lg md:text-5xl lg:text-6xl font-bold text-kidsAcademy">{{ $t("kidsAcademy.3.title") }}</p>
        <p class="text-sm md:text-base font-bold mt-4 text-kidsAcademy">{{ $t("kidsAcademy.3.description-1") }}</p>
        <p class="text-sm md:text-base font-bold mt-4 text-kidsAcademy">{{ $t("kidsAcademy.3.description-2") }}</p>
      </div>

      <div class="md:w-1/3">
        <img src="../assets/images/kidsAcademy/06-HEART.png" alt="">
      </div>
    </section>

    <section>
      <div class="">
        <img src="../assets/images/kidsAcademy/07-ARTWORK.png" alt="">
      </div>

      <div class="max-w-6xl mx-auto px-4 xl:px-0">
        <div class="mt-8">
          <img src="../assets/images/kidsAcademy/08-STATIONARY.png" alt="">
        </div>

        <div class="mt-8">
          <img src="../assets/images/kidsAcademy/09-BACKBAG.png" alt="">
        </div>

        <div class="mt-8">
          <img src="../assets/images/kidsAcademy/10-NOTEBOOk.png" alt="">
        </div>

        <div class="mt-8">
          <img src="../assets/images/kidsAcademy/11-INTERIOR.png" alt="">
        </div>

        <div class="mt-8">
          <img src="../assets/images/kidsAcademy/12-NOTEBOOKS.png" alt="">
        </div>
      </div>
    </section>
   
  </div>
</template>

<script>
export default {
  data() {
      return {
        info : false
      }
  },
  mounted() {
    scrollTo(0, 0)
  },
  methods:{
    toggle(){
      if(this.info == true){
        this.info = false
        document.body.style.overflow = ""
      }else{
        this.info = true
        scrollTo(0, 0)
        document.body.style.overflow = "hidden"
      }
      
    }
  }
}
</script>

<style>
  .background_kidsAcademy{
    background-image: url('../assets/images/kidsAcademy/01-Explore.png')
  }
</style>